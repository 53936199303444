.manage-bg .back {
    padding: 20px;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    border-bottom: 1px solid #C2C9D1;
}

.position-title {
    margin: 0 0 0 10px;
    display: flex;
    width: 98%;
    font-size: 12px;
    color: #9494AE;
    justify-content: space-between;
    margin-left: 0 10px;
    align-items: center;
}

.position-title label {
    width: 75%;
}

.position-data {
    display: flex;
    flex-direction: column;
    width: calc(100% - 100px);
}

.position-data span {
    color: #667085;
}

.primary-job {
    display: flex;
    align-items: flex-start; 
    width: 55px;  
}

.primary-job .css-j204z7-MuiFormControlLabel-root {
    margin-left: 0px;
    margin-right: 0px;
}

.save-positions {
    display: flex;
    justify-content: space-between;
    margin: 10px;
}

.blue-text {
    color: #006AFF;
}
