/* .sources-content{
    position: absolute;
    width: calc(100% - 340px);
    height: calc(100% - 156px);
    left: 274px;
    top: 93px;
    background: #FFFFFF;
    border-radius: 14px;
    padding: 20px;
}

.sources-header{
    display: flex;
    border-bottom: 1px solid #efefef;
    padding-bottom: 24px;
}

.sources-options{
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    max-height: 282px;
    padding-right: 10px;
    overflow: auto;
    align-content: baseline;
}

.side-bar-parent .inner-container .forms-checkbox{
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
}

.side-bar-parent .inner-container .forms-checkbox label{
    margin-left: 17px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #666666;
}

.side-bar-parent .inner-container .forms-checkbox input[type="checkbox"]{
    height: 22px;
    width: 22px;
    left: 5px;
    top: 5px;
    border-radius: 0px; 
}     */
.side-bar-parent .inner-container{
    padding: 30px 25px 26px 30px !important;
}
.side-bar-parent .inner-container .in-forms{
    height: calc(100% - 140px);
    padding-right: 10px;
    overflow: auto;
}
.sources{
    background-color: #fff;
}
.mr-none{
    margin-right: 0 !important;
}
.sources .inner-container{
    position: absolute;
    height: calc(100% - 99px);
    width: calc(100% - 304px);
    left: 244px;
    top: 66px;
    padding: 33px 30px 0 30px;
    background-color: #fff;
    overflow: hidden;
}
.sources .inner-container .top-url p{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #828282;
    margin: 0;
}
.sources .inner-container .top-url p a{
    color: inherit;
}
.sources .inner-container .top-url p strong{
    font-weight: 400;
    color: #1D1C1D;
}
.sources .inner-container .top-bar{
    display: flex;
    align-items: center;
    margin-top: 33px;
    padding-bottom: 18px;
    margin-bottom: 10px;
    overflow: auto;
}
.sources .inner-container .top-bar .small-box{
    background: #FFFFFF;
    border: 1px solid #F3F3F3;
    box-sizing: border-box;
    border-radius: 6px;
    display: flex;
    align-items: center;
    padding: 8px;
    margin-right: 10px;
    cursor: pointer;
    min-width: fit-content;
    transition: 1s all;
}
.sources .inner-container .top-bar .small-box:last-of-type{
    margin: 0;
}
.sources .inner-container .top-bar .small-box.active{
    background: #F2F5FF;
    border: 1px solid #DFE7FF;
    cursor: default;
    transition: 1s all;
}
.sources .inner-container .top-bar .small-box span{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #4F4F4F;
}
.sources .inner-container .top-bar .small-box.active span{
    color: #3954A5;
    margin-right: 11px;
}
.sources .inner-container .top-bar .small-box img{
    display: none;
}
.sources .inner-container .top-bar .small-box.active img{
    display: inline-block;
    cursor: pointer;
}
.sources .inner-container .add-types{
    border-top: 1px solid #E0E0E0;
    padding: 30px 0 15px 0;
    height: calc(100% - 181px);
    gap: 30px;
    display: flex;
    overflow: auto;
}
.sources .inner-container .add-types .parent-types{
    width: 300px;
    height: 100%;
    min-width: 300px;
}
.sources .inner-container .add-types .parent-types .add-types-box{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 25px;
    border-bottom: 3px solid #E6492D;
    padding-bottom: 26px;
    margin-bottom: 24px;
}
.sources .inner-container .add-types .label-length{
    background: #FFFFFF;
    border: 1px solid #D0D5DD;
    box-sizing: border-box;
    padding: 4px 15px;
    border-radius: 100px;
    margin-right: auto;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    margin-left: 10px;
    color: #BDBDBD;
}
.sources .inner-container .add-types .parent-types .add-types-box > p{
    margin: 0;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    text-transform: uppercase;
    color: #101828;
}
.sources .inner-container .add-types .parent-types .adding-items{
    background: #F6F6F8;
    border-radius: 14px;
    padding: 16px;
    max-height: calc(100% - 110px);
    overflow: auto;
}
.sources .inner-container .add-types .parent-types .adding-items .items{
    background: #FFFFFF;
    border-radius: 6px;
    padding: 8px 10px 8px 20px;
    display: flex;
    align-items: flex-start;
    margin-bottom: 6px;
    flex-direction: column;
}
.job-prefix{
    padding-top: 10px;
}
.job-prefix span:first-of-type{
    color: #667085;
    opacity: 0.7;
}
.sources .inner-container .add-types .parent-types .adding-items .items:last-of-type{
    margin-bottom: 0px;
}
.sources .inner-container .add-types .parent-types .adding-items .items span{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    margin-right: auto;
    color: #101828;
}
.sources .inner-container .add-types .parent-types .adding-items .items img,
.sources .inner-container .add-types .parent-types .add-types-box > img{
    cursor: pointer;
}
.add-btn{
    background: #006AFF;
    border-radius: 8px;
    border: none;
    padding: 10px;
    width: 100%;
    margin-top: 20px;
    font-style: 14px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #fff;
    cursor: pointer;
}
------------------changes-----------------------------------

.side-bar-parent .inner-container .forms-checkbox{
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
}

.side-bar-parent .inner-container .forms-checkbox label{
    margin-left: 17px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #666666;
}

.side-bar-parent .inner-container .forms-checkbox input[type="checkbox"]{
    height: 22px;
    width: 22px;
    left: 5px;
    top: 5px;
    border-radius: 0px; 
}
.parent-types .edit-popup{
    top: 42px;
    right: 20px;
}
.sources .MuiFormControlLabel-root{
    margin: 0;
}


.item-enter {
    opacity: 0;
}

.item-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
}

.item-exit {
    opacity: 1;
}

.item-exit-active {
    opacity: 0;
    transition: opacity 500ms ease-in;
}