.job {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.brand {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0px;
}

.brand select {
    padding: 5px 31px 5px 10px;
    color: #9494AE;
    margin-right: 15px
}

.brand label {
    margin-bottom: 0px;
    font-size: 0.9rem;
    color: #9494AE;
    margin-right: 13px;
    width: 130px;
}

.brand.facility label{
    width: 100px; 
}

.brand.status-container {
    margin-left: 20px;
}

.header-container-right {
    display: flex;
    flex-direction: row;
}

.j-progress-status {
    padding: 0 20px;
    border-bottom: 1px solid #E0E0E0;
    margin-bottom: 6px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.note-input input[type="text"]{
    border: 1px solid #D0D5DD;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 4px 24px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #101828;
    outline: none;
    line-height: 22px;
    background-color: #fff;
}

.note-input .btn-container {
    display: flex;
    justify-content: center;
    margin-top: 15px;
    margin-bottom: 15px;
}

.j-status {
    display: flex;
    gap: 7px;
    margin-bottom: 10px;
}

.j-status div {
    width: 28px;
    height: 28px;
    border-radius: 8px
}

.light-pink {
    background-color: #F2DCDB;
}

.light-green {
    background-color: #CAFFDD;
}

.light-yellow {
    background-color: #FFFFCC;
}

.b-border {
    border: 2px solid #B25050;
}

.sidebar-status {
    margin: 10px 0px;
    display: flex;
    width: 65%;
    gap: 10px;
}

.js-card {
    border-bottom: 1px solid #E0E0E0;
    padding: 0 24px;
}

.applicant-profile {
    display: flex;
    flex-direction: column;
}

.applicant-profile div {
    text-align: end;
}

.applicant-profile .icon.icon_button.reason_option_menu {
    display: flex;
    justify-content: flex-end;
    width: 95%;
}

.applicant-data {
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 26px;
    padding-bottom: 12px;
    border-bottom: 1px solid #E0E0E0;
    justify-content: space-between;
    padding-right: 24px;
    padding-left: 24px;
}

.applicant-data span {
    color: #333333;
}

.applicant-data strong {
    font-size: 1.3rem;
}

.add-btn.manage-btn.job {
    border-color: #3954A5;
    color: #3954A5;
    width: 210px;
    margin: 0px 0px 10px 0px;
    font-size: 14px;
}

.add-btn.manage-btn.job.video {
    border-color: #3954A5;
    color: #3954A5;
    width: 200px;
    margin: 0px 0px 14px 0px;
    font-size: 14px;
    padding: 2px 5px;
}

.common-container .inner-container .inner-h.applicant-sidebar {
    padding: 0px 24px;
    margin-top: 10px;
    height: calc(100% - 60px);
    overflow: auto;
}

.personal-data {
    display: grid;
    grid-template-columns: auto;
    row-gap: 22px;
}

.p-entity {
    display: flex;
    align-items: flex-start;
}

.p-label {
    font-weight: 600;
    font-size: 14px;
    line-height: 8px;
    color: #9EA0A5;
    margin: 0;
}

.p-data {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    margin: 8px 0 0 0;
}

.w-13 {
    width: 13%;
}

.main-ratings {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #9EA0A5;
    font-size: 0.75rem;
    margin-bottom: 20px;
}

.main-ratings .big {
    font-size: 2.8rem;
}

.sub-ratings {
    display: flex;
    flex-direction: column;
    font-size: 0.75rem;
}

.sub-ratings span {
    width: 45%;
}

.r-entity {
    display: flex;
}

.all-ratings {
    margin-bottom: 25px;
}

.common-container .inner-container .user-container .app-details .user-detail {
    width: 100%;
    overflow: unset;
}

.inner-section {
    padding: 16px;
    height: calc(100% - 400px);
}

.job-status {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.forms-input.curr-status select {
    padding: 2px 30px 2px 7px;
    border: 1px solid #333333;
    font-size: 12px;
    background-position-y: 1px;
    width: 135px;
    border-radius: 4px;
}

/* .common-container.users .MuiButtonBase-root{
    padding: 5px;
} */

.job-info {
    font-size: 12px;
    /* border-bottom: 1px solid #E0E0E0; */
    padding: 8px 5px 0px 5px;
    /* margin-bottom: 20px; */
}

.job-info div {
    width: 100%;
}

.j-field {
    display: flex;
    font-size: 12px;
}

.j-field label {
    max-width: 140px;
    color: #3E3F42;
    min-width: 100px;
}

.mb-15 {
    margin-bottom: 15px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-5 {
    margin-bottom: 5px;
}

.check-sections {
    height: 20px;
    width: 20px;
    border-radius: 4px;
    margin-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.check-sections.green {
    background-color: #34AA44;
}

.check-sections.blue {
    background-color: #3954A5;
}

.check-sections.grey {
    background-color: #E0E0E0;
}

.mt-20 {
    margin-top: 20px;
}

.interview-profile img {
    width: 85px;
    border-radius: 20px;
}

.profile-data {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.profile-data .section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    font-size: 12px;
    min-width: 240px;
    width: 100%;
}

.inter-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

/* @media only screen and (min-width: 1500px) {
    .inter-container {
        gap: 20px 30px;
    }

    .profile-data .section {
        width: 29%;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
    .inter-container {
        gap: 20px 30px;
    }

    .profile-data .section {
        width: 100%;
    }
}

@media only screen and (min-width: 1000px) and (max-width: 1200px) {
    .inter-container {
        gap: 20px 30px;
    }

    .profile-data .section {
        width: 100%;
    }
} */

.profile-data .section span.req-date {
    font-size: 12px;
}

.profile-data .section span.scheduled {
    font-size: 12px;
}

.profile-data .section div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.profile-data .section div strong {
    word-break: break-all;
}

.job-document {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.document-section {
    padding-top: 20px;
    border-top: 1px solid #E0E0E0;
    margin-top: 20px;
}

.document.applicant .documents-upload span {
    width: 130px;
    font-size: 14px;
}

.doucments-card.applicant {
    margin-top: 15px;
}

.documents-upload {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #D0D5DD;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 6px 14px;
    background: #f3f4f6;
    color: #4b5563;
    z-index: -1;
}

.documents-upload span {
    width: 55%;
}

.job-posted {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.inner-h.job-sidebar {
    height: calc(100% - 286px) !important;
    overflow: auto;
    padding: 0px 20px 0px 0px !important;
}

.inner-h.applicant-list {
    height: calc(100% - 34px) !important;
    overflow: auto;
    padding: 0px 20px 0px 0px !important;
}

.common-container .inner-container .user-container .user-detail.job-details {
    width: calc(100% - 366px);
    background: #FFFFFF;
    border-radius: 14px;
    padding-bottom: 20px;
}

.note-input .forms-input textarea {
    border-radius: 4px;
}

/* #vertical-tabpanel-6 .MuiBox-root.css-19midj6 {
    padding: 0px;
} */
