.navbar{
    height: 66px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    background: #3954A5;
    transition: 0.6s all;
}
.whitelogo_img{
    height: 40px;
    width: 160px;
    margin-right: auto
}

.user_options{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    position: static;
    width: 220px;
    left: 6px;
    top: 9px;
    flex: none;
    order: 0;
    flex-grow: 0;
    justify-content: space-between;
}

.search{
    width: 48px;
    height: 48px;
    left: 0px;
    top: 0px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.search-icon{
    height: 24px;
    width: 24px;
    margin-top: 12px;
    margin-bottom: 12px;
    border-radius: 0px;
}

.notification{
    width: 48px;
    height: 48px;
    position: relative;
    flex: none;
    order: 1;
    flex-grow: 0;
    z-index: 1;
}
.notifi-popup-nav{
    background: #FFFFFF;
    box-shadow: -15px 4px 38px rgb(0 0 0 / 12%);
    border-radius: 14px;
    width: 340px;
    height: 530px;
    position: absolute;
    left: -200px;
    padding: 30px;
}
.notifi-popup-nav > p{
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #1D1C1D;
    margin: 0 0 28px 0;
    position: relative;
}
.notifi-popup-nav > p::after{
    content: '';
    position: absolute;
    top: -39px;
    left: calc(50% + 4px);
    background-image: url("../../../images/top-arrow-popup.svg");
    width: 17px;
    height: 15px;
}
.notifi-popup-nav .cards-notifi{
    display: flex;
    align-items: flex-start;
    border-bottom: 1px solid #E0E0E0;
    padding-bottom: 18px;
    cursor: pointer;
    margin-bottom: 15px;
}
.notifi-popup-nav .cards-notifi:last-of-type{
    margin-bottom: 0;
}
.notifi-popup-nav .cards-notifi > img{
    width: 48px;
    height: 48px;
    border-radius: 50%;
}
.notifi-popup-nav .cards-notifi .cards-name{
    display: flex;
}
.notifi-popup-nav .cards-notifi .cards-name .cards-heading{
    width: calc(100% - 65px);
}
.notifi-popup-nav .cards-notifi .cards-name .cards-heading > p{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #1D1C1D;
    margin: 0 0 0 13px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.btn-notifi{
    background: #006AFF;
    border-radius: 8px;
    padding: 12px;
    width: 100%;
    border: none;
    color: #fff;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-top: 23px;
    cursor: pointer;
}
.notifi-popup-nav .cards-notifi .cards-name .cards-heading > p strong{
    font-weight: normal;
    color: #9EA0A5;
}
.notifi-popup-nav .cards-notifi .cards-name .read.active{
    background: #2A85FF;
}
.notifi-popup-nav .cards-notifi .cards-name .read{
    background: #D5D9EB;
    width: 12px;
    height: 12px;
    margin: 0;
    border-radius: 100%;
    margin-top: 6px;
}
.notifi-popup-nav .cards-notifi .cards-name .timing{
    margin: 0 8px 0;
    font-weight: 700;
    font-size: 14px;
    line-height: 26px;
    color: #9EA0A5;
}
.help{
    width: 48px;
    height: 48px;
    left: 118px;
    top: 0px;
    order: 2;
    flex-grow: 0;
}

.divider{
    height: 24px;
    left: 177px;
    top: 12px;
    flex: none;
    order: 3;
    flex-grow: 0;
}

.divider-line{
    height: 32px;
    border-right: 2px solid #e5e5e5;  
}
.divider-line.company-active{
    border-color: #3954A5;
    opacity: 0.2;
}

.profile{
    left: 80.8%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    flex: none;
    order: 4;
    flex-grow: 0;
}

.profile-icon{
    height: 48px;
    width: 48px;
    left: 202px;
    top: 0px;
    border-radius: 24px;
}

.icon{
    cursor: pointer;
}
.navbar.company-active{
    width: calc(100% - 284px);
    margin-left: 244px;
    position: absolute;
    background-color: #fff;
}
.menu-company{
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #3E3F42;
    margin-left: 15px;
}
.navbar.company-active.collapse {
    width: calc(100% - 154px);
    margin-left: 114px;
}
.notifi-block {
    display: flex;
    /* max-height: 600px;
    max-width: 450px; */
}
.notifi-msg {
    font-size: 14px;
}
.notifi-msg span {
    font-weight: 600;
}
.notifi-msg label {
    color: #667085;
}
.notifi-msg .timing {
    color: #667085;
    text-align: right;
    font-size: 12px;
}
.notifi-block .notifi-msg .cards-heading {
    word-break: break-word;
}
