.company-content {
    background: #fff;
    border-radius: 14px;
    padding: 20px;
    position: relative;
    width: 300px;
    height: fit-content;
    margin: auto;
    top: 190px;
    display: block;
}

.company-content .container {
    display: flex;
    flex-direction: column;
}

.company-content .container img {
    margin-top: 10px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.choose-title {
    margin-top: 20px;
    padding-bottom: 10px;
    margin-bottom: 20px;
    color: #94a3b8;
    border-bottom: 1px solid #e2e8f0;
    text-align: center;
}

.company-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 60px;
}

.company-name {
    font-weight: 600;
    color: #475569;
}