.job-data-container {
    background: #FFFFFF;
}

.job-data-container .header {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.job-data-container .header img {
    padding: 20px;
}

.job-data-container .j-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 60px;
}

.physical-request-container {
    text-align: left;
    height: fit-content;
}

.apt-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.question-title {
    font-size: 14px;
    margin: 14px 14px 14px 0px;
    text-align: left;
}

.rating-video {
    width: 600px;
    height: 350px;
    display: block;
}

.white-container.form {
    width: 65%;
}
