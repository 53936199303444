------------------changes----------------------------------- .users {
    background-color: #fff;
}

.common-container .inner-container {
    position: absolute;
    height: calc(100% - 99px);
    width: calc(100% - 304px);
    left: 244px;
    top: 66px;
    padding: 33px 30px 0 30px;
    background-color: #fff;
    overflow: hidden;
    /* changes for manage notifications page scroll] */
}

.common-container .inner-container .top-url {
    margin-bottom: 33px;
}

.common-container .inner-container .top-url p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
    color: #828282;
}

.common-container .inner-container .top-url p a {
    color: inherit;
}

.common-container .inner-container .top-url p strong {
    font-weight: 400;
    color: #1D1C1D;
}

.common-container .inner-container .user-container {
    display: flex;
    gap: 20px;
    height: 100%;
}

.common-container .inner-container .user-container .user-list {
    width: 346px;
    background: #FFFFFF;
    border-radius: 14px;
    padding: 14px 0;
    height: calc(100% - 22px);
    min-width: 346px;
}

.common-container .inner-container .user-container .user-detail {
    width: calc(100% - 366px);
    background: #FFFFFF;
    border-radius: 14px;
    padding-bottom: 20px;
    height: calc(100% - 15px);
    overflow: auto;
    max-height: fit-content;
}

.common-container .inner-container .user-container .app-details {
    display: flex;
    flex-direction: column;
    width: calc(100% - 366px);
    border-radius: 14px;
    padding-bottom: 20px;
    height: 100%;
    overflow: auto;
    justify-content: space-between;
}

.common-container .inner-container .user-container .user-list .user-info {
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 14px;
    margin-bottom: 10px;
}

.common-container .inner-container .user-container .user-list .user-info .user-header {
    display: flex;
    align-items: center;
}

.common-container .inner-container .user-container .user-list .user-info .user-header>img {
    width: 26px;
    height: 26px;
    margin-right: 18px;
}

.common-container .inner-container .user-container .user-list .user-info {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* max-height: 120px; */
    cursor: pointer;
    transition: 0.5s all;
}
.no-doc > p{
    margin: 57px 0;
    text-align: center;
}
.common-container .inner-container .user-container .user-list .user-info:hover {
    box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
}

.common-container .inner-container .user-container .user-list .bottom-btn {
    border-top: 1px solid #E0E0E0;
    padding: 0 15px;
}

.common-container .inner-container .inner-h {
    height: calc(100% - 60px);
    padding: 0 24px;
    overflow: auto;
}

.common-container .j-info.inner-container .inner-h {
    height: calc(100% - 60px);
    padding: 0;
    overflow: auto;
}

.common-container .inner-container .profile-sidebar {
    height: calc(100% - 286px) !important;
    /* padding: 0 24px; */
    padding: 0;
    overflow: auto;
}

.common-container .inner-container .user-container .user-list .user-info.active {
    background: #F2F5FF;
    border-color: #F2F5FF;
}

.common-container .inner-container .user-container .user-list .user-info .user-header span {
    color: #101828;
}

.common-container .inner-container .user-container .user-list .user-info>p {
    color: #667085;
    margin: 14px 0 0 0;
}


.common-container.users .inner-container .user-container,
.common-container.users .inner-container .manage-container {
    background: #F6F6F8;
    border-radius: 14px 14px 0 0;
    padding: 20px;
    border-radius: 14px;
    height: calc(100% - 115px);
    overflow-x: auto;
    overflow-y: hidden;
}

.common-container.users .inner-container .user-container.company {
    background: #F6F6F8;
    border-radius: 14px 14px 0 0;
    padding: 20px;
    border-radius: 14px;
    height: calc(100% - 40px);
}

.common-container.users .inner-container .user-detail .top-header-user {
    padding: 20px 28px;
    border-bottom: 1px solid #e0e0e0;
    align-items: center;
    display: flex;
}

.common-container.users .inner-container .user-detail .top-header-user .main-name {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    color: #000000;
    margin-right: auto;
}

.common-container.users .inner-container .user-detail .top-header-user .check-status {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #333333;
    margin-right: 18px;
}

.common-container.users .inner-container .user-detail .personal-info {
    padding: 20px 28px;
    border-bottom: 1px solid #e0e0e0;
}

.common-container.users .inner-container .user-detail .personal-info .main-head {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    /* margin: 0 0 26px 0; */
    color: #000;
}

.common-container.users .inner-container .user-detail .personal-info .personal-data {
    display: grid;
    grid-template-columns: auto auto;
    row-gap: 35px;
}

.common-container.users .inner-container .user-detail .personal-info .personal-data .p-entity {
    display: flex;
    align-items: flex-start;
}

.common-container.users .inner-container .user-detail .personal-info .personal-data .p-entity>img, 
.common-container.users .inner-container .user-detail .personal-info .personal-data .p-entity>svg {
    margin-right: 20px;
}

.common-container.users .inner-container .user-detail .personal-info .personal-data .p-entity .p-label {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #9EA0A5;
    margin: 0;
}

.common-container.users .inner-container .user-detail .personal-info .personal-data .p-entity .p-data {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    margin: 8px 0 0 0;
}

.common-container.users .inner-container .user-detail .user-roles {
    padding: 20px 28px;
    border-bottom: 1px solid #e0e0e0;
}

.common-container.users .inner-container .user-detail .user-roles .main-head {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    /* margin-bottom: 12px; */
}

.common-container.users .inner-container .user-detail .user-roles .add-roles-container {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 42px;
    row-gap: 25px;
}

.common-container.users .inner-container .user-detail .user-roles .add-roles-container .role-tabs {
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 10px 14px;
    justify-content: space-between;
}

.common-container.users .inner-container .user-detail .user-roles .add-roles-container .role-tabs span:nth-of-type(1) {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #333;
}

.common-container.users .inner-container .user-detail .job-management {
    padding: 20px 28px;
}

.common-container.users .inner-container .user-detail .job-management .main-head {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    /* margin-bottom: 22px; */
}

.common-container.users .inner-container .user-detail .job-management .job-tabs {
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
    padding: 14px;
    border-radius: 8px;
    margin-bottom: 15px;
    display: flex;
    align-items: flex-start;
}

.common-container.users .inner-container .user-detail .job-management .job-tabs>img {
    margin-right: 16px;
}

.common-container.users .inner-container .user-detail .job-management .job-tabs .job-in-name>span {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #101828;
    margin-bottom: 13px;
}

.common-container.users .inner-container .user-detail .job-management .job-tabs .job-in-name .job-content {
    margin-top: 10px;
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 50px;
}

.common-container.users .inner-container .user-detail .job-management .job-tabs .job-in-name .job-content span {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #667085;
}

.common-container.users .inner-container .user-detail .job-management .job-tabs .job-in-name .job-content span strong {
    font-weight: 400;
    color: #030229;
}

.add-btn.manage-btn {
    background: transparent;
    border: 1px solid #006AFF;
    color: #006AFF;
    padding: 3px 8px;
    width: 265px;
    text-align: left;
    margin: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.right-arrow-btn {
    transform: rotate(180deg);
}

.delete-can {
    width: 16px;
    height: 16px;
}



.side-bar-parent .inner-container .forms-input label {
    color: #000000;
}

.side-bar-parent .inner-container .forms-checkbox {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
}

.side-bar-parent .inner-container .forms-checkbox label,
.facilities_desc label {
    /* margin-left: 19px; */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
}

.side-bar-parent .inner-container .forms-checkbox span,
.side-bar-parent .inner-container .forms-inputfill label,
.facilities_desc span {
    /* margin-left: 17px; */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #666666;
    /* margin-top: 5px; */
}

.side-bar-parent .inner-container .forms-inputfill label {
    margin: 0;
    margin-right: auto;
}

.side-bar-parent .inner-container .forms-inputfill .company_label{
    color: black;
    font-weight: 600;
}

.side-bar-parent .inner-container .forms-inputfill .facility_label{
    margin-right: 0px;
}

.side-bar-parent .inner-container .forms-inputfill .main-desc:first-of-type {
    margin-top: 10px;
}

.side-bar-parent .inner-container .forms-inputfill .main-desc {
    font-weight: 600;
    font-size: 13px;
    line-height: 22px;
    color: #000000;
    margin-left: 15px;
    position: relative;
}

.side-bar-parent .inner-container .forms-inputfill .main-desc::after {
    content: '';
    position: absolute;
    top: 8px;
    left: -15px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #000;
}

.side-bar-parent .inner-container .forms-inputfill .sub-desc {
    font-size: 13px;
    line-height: 22px;
    color: #000000;
    margin-left: 15px;
    max-width: 180px;
}

.side-bar-parent .inner-container .forms-checkbox input[type="checkbox"] {
    height: 22px;
    width: 22px;
    left: 5px;
    top: 5px;
    border-radius: 0px;
}

.facilities_desc {
    display: flex;
    flex-direction: column;
}

.side-bar-parent .inner-container .forms-inputfill {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
}

.side-bar-parent .inner-container .forms-inputfill span {
    margin-left: 20px;

}

.manage-bg {
    background: #FFFFFF;
    border-radius: 14px;
    height: 100%;
}

.manage-bg.active {
    background-color: transparent;
}

.manage-bg .manage-content {
    padding: 20px;
    height: calc(100% - 127px);
    overflow: auto;
}

.manage-title>p {
    margin: 0;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    color: #000000;
    padding: 30px 20px;
    border-bottom: 1px solid #E0E0E0;
}

.manage-bg .manage-content .MuiPaper-root {
    box-shadow: none;
    margin-bottom: 16px;
}

.manage-bg .manage-content .MuiPaper-root:last-of-type {
    margin-bottom: 0;
}

.manage-bg .manage-content .MuiPaper-root::before {
    content: unset;
}

.manage-bg .manage-content .MuiAccordionSummary-root {
    background: #F6F6F8;
    border-radius: 6px;
    padding: 7px 24px;
    max-height: 64px;
    width: 100%;
}

.manage-bg .manage-content .MuiCheckbox-root,
.manage-bg .MuiCheckbox-root {
    padding: 0;
}

.manage-bg .manage-content .right-checks .MuiFormControlLabel-root .MuiTypography-root {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #000000;
    margin-left: 10px;
}
/* .common-container.users .MuiButtonBase-root{
    // padding-top: 12px;
    padding-right: 16px;
    padding-bottom: 12px;
    padding-left: 16px;
} */

.manage-bg .manage-content .MuiCheckbox-root,
.manage-bg .MuiCheckbox-root,
.select-doc-container .MuiCheckbox-root,
.forms-input.check-box .MuiCheckbox-root {
    color: #3954A5;
}

.manage-bg .manage-content .MuiCheckbox-colorSecondary.Mui-checked,
.manage-bg .MuiCheckbox-colorSecondary.Mui-checked,
.select-doc-container .MuiCheckbox-colorSecondary.Mui-checked,
.forms-input.check-box .MuiCheckbox-colorSecondary.Mui-checked{
    color: #3954A5;
}

.select-doc-container{
    border-bottom: 1px solid #E0E0E0;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.select-doc{
    margin-top: 27px;
}

.select-doc-container .MuiFormControlLabel-label{
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #101828;
    font-family: 'Inter';
}
.forms-input.check-box .MuiFormControlLabel-label{
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: #101828;
    font-family: 'Inter';
}

.select-doc-container .select-doc-desc{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #667085;
    margin: 0;
    padding-left: 32px;
}

.add-upload{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: right;
    color: #2E90FA;
    cursor: pointer;
    margin-top: 28px;
}

.add-upload-container{
    background: #EFF8FF;
    border-radius: 8px;
    padding: 10px;
    margin-top: 20px;
}

.add-upload-container.doc {
    background-color: transparent;
}

.side-bar-parent .inner-container .add-upload-container .forms-input{
    padding: 0 10px;
}

.manage-bg .manage-content .MuiAccordionSummary-root.Mui-expanded {
    border-radius: 6px 6px 0 0px;
}

.manage-bg .manage-content .MuiTypography-root {
    font-family: 'Inter';
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #101828;
}

.manage-bg .manage-content .MuiAccordion-region {
    background: #FFFFFF;
    border: 1px solid #F6F6F8;
    border-top: none;
    box-sizing: border-box;
    border-radius: 0px 0px 6px 6px;
    padding: 20px 24px;
}

.manage-bg .manage-content .MuiAccordionDetails-root {
    margin-bottom: 16px;
    padding: 0;
}

.manage-bg .manage-content .MuiAccordionDetails-root:last-of-type {
    margin-bottom: 0;
}

.manage-bg .manage-content .left-content {
    width: 300px;
}

.manage-bg .manage-content .left-content span {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #101828;
}

.manage-bg .manage-content .right-checks {
    display: grid;
    grid-template-columns: auto auto auto auto;
    width: calc(100% - 300px);
}

.manage-bg .manage-content .checks-container {
    margin-bottom: 30px;
}

.manage-bg .manage-content .checks-container:last-of-type {
    margin-bottom: 0px;
}

/* card{
    box-shadow: none;
}
card in{
    background: #F6F6F8;
    border-radius: 6px;
    padding: 7px 24px;
    max-height: 64px;
}
p{
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    font-family: 'Inter';
    color: #101828;
} */

.manage-checkboxes {
    display: grid;
    grid-template-columns: auto auto;
    gap: 20px;
}

/* .checkboxes-right{
    margin-left: 100px;
} */

.job-info {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    background: #fff;
    padding: 15px 5px 15px 5px;
    margin: 0;
}

.common-container.users .inner-container .manage-container.jobs {
    padding: 20px 0;
}

.common-container.users .inner-container .manage-container.jobs .parent-container-d {
    height: 100%;
    padding: 0 20px 20px;
}
.common-container.users .inner-container .manage-container.jobs .parent-container-d .manage-checkboxes-none.specific-facilities{
    height: calc(100% - 115px);
    /* overflow: auto; */
}
.search_input.appointments::placeholder{
    color: #9494AE;
}
.common-container.users .inner-container .manage-container.jobs .btns-container {
    padding: 31px 35px;
    border-top: 1px solid #E0E0E0;
    display: flex;
    justify-content: space-between;
}
.top-search{
    padding-bottom: 20px;
    border-bottom: 1px solid rgb(0 0 0 / 10%);
    /* margin-bottom: 14px; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 450px;
}
.top-search .add-btn{
    margin-top: 0;
    width: auto;
    padding: 8px 16px;
}
.company_search{
    margin-left: 0;
    max-width: 225px;
}

.common-container.users .inner-container .manage-container.jobs .btns-container .save-btn {
    margin: 0;
    width: auto;
    padding: 12px 50px;
}

.innner-checkboxes {
    margin-left: 0;
}

/* .job-info input[type="checkbox"]{
    height: 22px;
    width: 22px;
    left: 5px;
    top: 5px;
    border-radius: 0px;  
} */

.add-btn.save-btn {
    width: 8%;
    float: right;

}
.common-container.users .inner-container .manage-container.quick{
    border-top: 1px solid #E0E0E0;
    background: #fff;
    border-radius: 0;
    height: calc(100% - 98px);
}
.manage-container.quick .top-search{
    border: 0;
}
.manage-checkboxes-none.position-relative{
    position: relative;
    height: calc(100% - 75px);
    overflow: hidden;
}
.common-container.users .inner-container .manage-container.jobs.quick .parent-container-d{
    height: 100%;
    padding: 0;
    overflow: unset;
}
.cards-contain{
    display: flex;
    gap: 10px 20px;
    flex-wrap: wrap;
}
.cards-contain.active{
    gap: 20px;
}
.cards-contain .cust-cards{
    background: #FFFFFF;
    border-radius: 6px;
    padding: 20px;
    position: relative;
    width: calc(100% / 3 - 60px);
    word-break: break-word;
    transition: 0.5s all;
}
.inner-container.documents .cards-contain{
    gap: 17px 20px;
}
.css-apqrd9-MuiTableBody-root.t-body{
    height: calc(100% - 50px);
}
.inner-container.documents .cards-contain .cust-cards{
    padding: 16px;
    min-width: 240px;
    width: calc(100% / 4 - 50px);
    font-size: 14px;
}

@media only screen and (max-width: 400px) {
    .inner-container.documents .cards-contain .cust-cards{
        padding: 16px;
        min-width: 300px;
        width: (100% / 1 - 40px);
        font-size: 14px;
    }
}

@media only screen and (max-width: 800px) and (min-width: 600px)  {
    .inner-container.documents .cards-contain .cust-cards{
        padding: 16px;
        min-width: 240px;
        width: (100% / 1 - 40px);
        font-size: 14px;
    }
}

@media only screen and (max-width: 1200px) and (min-width: 800px)  {
    .inner-container.documents .cards-contain .cust-cards{
        padding: 16px;
        min-width: 300px;
        width: (100% / 2 - 30px);
        font-size: 14px;
    }
}

.inner-container.documents .cards-contain .cust-cards::before, .inner-container.documents .cards-contain .cust-cards::after{
    content: unset;
}
.cards-contain .cust-cards::before{
    content: '';
    right: -1px;
    top: -1px;
    background-image: url('../../../images/triangle-card-paper.svg');
    background-size: 100%;
    width: 30px;
    height: 25px;
    position: absolute;
}
.cards-contain .cust-cards::after{
    content: '';
    right: -1px;
    top: -1px;
    background-image: url('../../../images/triangle-card-paper-clear.svg');
    background-size: 100%;
    width: 30px;
    height: 25px;
    position: absolute;
    transform: rotate(180deg);
}
.cards-contain .cust-cards .date-sec{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 15px);
}
.inner-container.documents .cards-contain .cust-cards .date-sec{
    width: 100%;
}
.inner-container.documents .cards-contain .cust-cards a{
    color: black;
    text-decoration: underline;
}
.cards-contain .cust-cards .date-sec > span{
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #828282;
}
.inner-container.documents .cards-contain .cust-cards .date-sec > span{
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #101828;
}
.cards-contain .cust-cards > p, .cards-contain .cust-cards .date-sec.active > p{
    margin: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-top: 12px;
    color: #667085;
    cursor: default;
    transition: 0.5s all;
}
.cards-contain .cust-cards:hover > p{
    color: #000;
}
.cards-contain .cust-cards:hover{
    box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
}
.cards-contain .cust-cards:hover::before,.cards-contain .cust-cards:hover::after{
    content: unset;
}
.manage-container.quick .manage-checkboxes-none{
    background: #F6F6F8;
    border-radius: 14px 14px 0 0;
    padding: 20px;
    height: calc(100% - 102px);
    overflow: auto;
}
.inner-container .MuiTabs-indicator{
    background-color: #0890FE;
}
.box-shadow-none{
    box-shadow: none !important;
    height: calc(120vh - 100%);
    overflow-y: scroll;
    /* overflow: unset !important; */
}

.box-shadow-none.t-bg {
    width: 101%;
    height: 100%;
    background-color: #F6F6F8;
}

.css-wt6ihn-MuiTableRow-root td, .css-wt6ihn-MuiTableRow-root th {
    border-bottom: 2px solid #F6F6F8;
}

.side-bar-parent .inner-container .forms-inputfill label.note-label{
    background: rgba(255, 127, 0, 0.05);
    border-radius: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #FF7F00;
    padding: 10px 14px;
}

.cancel-btn.save-btn {
    background: #fff;
    color: #101323;
    border: 1px solid #2E90FA;
    border-radius: 8px;
    padding: 20px;
    margin-top: 20px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 5px;
    cursor: pointer;
}
.common-container.users .edit-popup{
    top: 27px;
}
.common-container.users .edit-popup.active ul{
    width: 200px;
}
.notes-table{
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #98A2B3;
    margin: 10px 0 0 0;
    opacity: 0.7;
}
.content-table{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #667085;
    margin: 0;
}
.close-bar-active{
    padding: 11px 10px;
    vertical-align: middle;
    cursor: pointer;
}


.manage-checkboxes-none.position-relative.message-box{
    display: flex;
    gap: 20px;
    height: calc(100% - 0px);
}
.message-container-left, .message-container-right{
    background: #fff;
    border-radius: 10px;
}
.message-count{
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 26px;
    color: #1D1C1D;
    margin: 0;
    padding: 31px 24px 38px 24px;
    border-bottom: 1px solid #e0e0e0;
}
.message-container-left{
    width: 336px;
}
.message-container-right{
    width: calc(100% - 356px);
}
.message-container-left .message-list {
    height: calc(100% - 97px);
    overflow: auto;
}
.message-container-left .message-list .message-card{
    /* display: flex; */
    justify-content: space-between;
    padding: 10px 10px 15px 24px;
    border-bottom: 1px solid #E0E0E0;
    transition: 0.6s all;
    cursor: pointer;
}
.message-container-left .message-list .message-card:hover, .message-container-left .message-list .message-card.active{
    background: #F0F7FF;
    color: #2E90FA;
    transition: 0.6s all;
}
.message-container-left .message-list .message-card:hover > span, .message-container-left .message-list .message-card.active > span{
    color: #2E90FA;
    transition: 0.6s all;
}
.message-container-left .message-list .message-card > span{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    max-width: 200px;
    color: #333333;
}
.message-container-right .message-name{
    padding: 30px 20px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #E0E0E0;
}
.message-container-right .message-restore{
    height: 42px;
    padding: 27px;
}
.message-container-right .message-name > span{
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    color: #030229;
}
.message-subject{
    display: flex;
    gap: 70px;
}
.message-subject .forms-input > input{
    border: 1px solid #D0D5DD;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 10px 14px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #101828;
    outline: none;
    line-height: 24px;
    background-color: #fff;
}
.message-details{
    padding: 30px 20px;
    height: calc(100% - 250px);
    overflow: auto;
}
.message-subject > .forms-input{
    display: flex;
    flex-direction: column;
    max-width: 310px;
}
.message-container-right .forms-input label{
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #030229;
    margin-bottom: 15px;
}
.message-content .message-content-p{
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #030229;
    margin: 40px 0 20px 0;
    vertical-align: middle;
}
.message-content .message-content-p > img{
    vertical-align: bottom;
    margin-left: 20px;
    cursor: pointer;
}
.message-content .format-detail > p{
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: #333333;
}
.message-content .format-detail > p a{
    color: #2E90FA;
}


.alert-enter {
    opacity: 0;
    transform: scale(0.9);
}
.alert-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
}
.alert-exit {
    opacity: 1;
}
.alert-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
}
.fade-in-section {
    opacity: 0;
    transform: translateY(10vh);
    visibility: hidden;
    transition: opacity 1200ms ease-out, transform 600ms ease-out,
      visibility 1200ms ease-out;
    will-change: opacity, transform, visibility;
}
.fade-in-section.is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
}

.side-bar-parent .inner-container .upload-file-shower a{
    color: black;
    text-decoration: underline;
}

.add-upload-container .add-btn{
    margin-right: 16px;
}

.cancel-btn.restore-btn{
    background: #fff;
    color: #101323;
    border: 1px solid #2E90FA;
    border-radius: 8px;
    padding: 8px;
    font-size: 14px;
    cursor: pointer;
}

.restore-img{
    vertical-align: middle;
}

.message-container-left .message-list .message-card .message-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.message-container-left .message-list .message-card>p {
    color: #667085;
    margin: 14px 0 0 0;
}

.user-roles-pop-up {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
}

.user-roles-pop-up .MuiTypography-body1 {
    margin: 5px 10px 5px 10px;
}

.user-roles-pop-up .MuiCheckbox-colorSecondary.Mui-checked {
    color: #3954A5;
}

.fat-edit-popup {
    width: 126%;
}


@media only screen and (min-width: 1400px) {
    .cards-contain .cust-cards{
        width: calc(100% / 4 - 60px);
    }
}
@media only screen and (min-width: 1650px) {
    .applicant-cards .app-card {
        width: calc(100% / 4 - 47px);
    }
}
@media only screen and (min-width: 1920px) {
    .cards-contain .cust-cards{
        width: calc(100% / 5 - 60px);
    }
    .applicant-cards .app-card {
        width: calc(100% / 5 - 48px);
    }
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAccordion-root.MuiAccordion-rounded.MuiAccordion-gutters.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
    width: 100%;
}

/* .MuiButtonBase-root.MuiAccordionSummary-root.MuiAccordionSummary-gutters.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root {
    display: flex;
} */

.MuiAccordionSummary-content.MuiAccordionSummary-contentGutters.css-o4b71y-MuiAccordionSummary-content {
    margin: 0px;
}

.MuiAccordionDetails-root.css-15v22id-MuiAccordionDetails-root {
    padding: 0px 16px 0px;
}
