------------------changes----------------------------------- .users {
    background-color: #fff;
}

.common-container .inner-container {
    position: absolute;
    height: calc(100% - 99px);
    width: calc(100% - 304px);
    left: 244px;
    top: 66px;
    padding: 33px 30px 0 30px;
    background-color: #fff;
    overflow: hidden;
    /* changes for manage notifications page scroll] */
}

.common-container .inner-container .top-url {
    margin-bottom: 33px;
}

.common-container .inner-container .top-url p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
    color: #828282;
}

.common-container .inner-container .top-url p a {
    color: inherit;
}

.common-container .inner-container .top-url p strong {
    font-weight: 400;
    color: #1D1C1D;
}

.common-container .inner-container .user-container {
    display: flex;
    gap: 20px;
    height: 100%;
}

.common-container .inner-container .user-container .user-list {
    width: 346px;
    background: #FFFFFF;
    border-radius: 14px;
    padding: 14px 0;
    height: calc(100% - 50px);
}

.common-container .inner-container .user-container .user-detail {
    width: calc(100% - 366px);
    background: #FFFFFF;
    border-radius: 14px;
    padding-bottom: 20px;
    height: calc(100% - 22px);
    overflow: auto;
}

.common-container .inner-container .user-container .user-list .user-info {
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 14px;
    margin-bottom: 10px;
}

.common-container .inner-container .user-container .user-list .user-info .user-header {
    display: flex;
    align-items: center;
}

.common-container .inner-container .user-container .user-list .user-info .user-header>img {
    width: 26px;
    height: 26px;
    margin-right: 18px;
}

.common-container .inner-container .user-container .user-list .user-info {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* max-height: 120px; */
    cursor: pointer;
    transition: 0.5s all;
}

.btn-loader{
    margin-top: 28px;
    position: absolute;
    left: calc(50% - 70px);
}
.btn-loader .circular{
    height: 30px;
    width: 30px;
}
.btn-loader .path {
    stroke-width: 4;
    stroke-miterlimit: 10;
    animation: dash 1.5s ease-in-out infinite, color-white 6s ease-in-out infinite;
}

.common-container .inner-container .user-container .user-list .user-info:hover {
    box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
}

.common-container .inner-container .user-container .user-list .bottom-btn {
    border-top: 1px solid #E0E0E0;
    padding: 0 15px;
}

.common-container .inner-container .inner-h {
    height: calc(100% - 60px);
    padding: 0 24px;
    overflow: auto;
}

.common-container .inner-container .profile-sidebar {
    height: calc(100% - 286px) !important;
    /* padding: 0 24px; */
    padding: 0;
    overflow: auto;
}

.common-container .inner-container .user-container .user-list .user-info.active {
    background: #F2F5FF;
    border-color: #F2F5FF;
}

.common-container .inner-container .user-container .user-list .user-info .user-header span {
    color: #101828;
}

.common-container .inner-container .user-container .user-list .user-info>p {
    color: #667085;
    margin: 14px 0 0 0;
}


.common-container.users .inner-container .user-container,
.common-container.users .inner-container .manage-container {
    background: #F6F6F8;
    border-radius: 14px 14px 0 0;
    padding: 20px;
    height: calc(100% - 95px);
}

.common-container.users .inner-container .user-detail .top-header-user {
    padding: 20px 28px;
    border-bottom: 1px solid #e0e0e0;
    align-items: center;
    display: flex;
}

.common-container.users .inner-container .user-detail .top-header-user .main-name {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    color: #000000;
    margin-right: auto;
}

.common-container.users .inner-container .user-detail .top-header-user .check-status {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #333333;
    margin-right: 18px;
}

.common-container.users .inner-container .user-detail .personal-info {
    padding: 31px 28px;
    border-bottom: 1px solid #e0e0e0;
}

.common-container.users .inner-container .user-detail .personal-info .main-head {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    /* margin: 0 0 26px 0; */
    color: #000;
}

.common-container.users .inner-container .user-detail .personal-info .personal-data {
    display: grid;
    grid-template-columns: auto auto;
    row-gap: 35px;
}

.common-container.users .inner-container .user-detail .personal-info .personal-data .p-entity {
    display: flex;
    align-items: flex-start;
}
.updated-design{
    color: #667085;
    padding-left: 40px;
}
.user-roles-pop-up .border-bottom{
    border-bottom: 1px solid #E0E0E0;
    padding-bottom: 8px;
    margin-bottom: 4px;
}
.common-container.users .inner-container .user-detail .personal-info .personal-data .p-entity>img {
    margin-right: 20px;
}

.common-container.users .inner-container .user-detail .personal-info .personal-data .p-entity .p-label {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #9EA0A5;
    margin: 0;
}

.common-container.users .inner-container .user-detail .personal-info .personal-data .p-entity .p-data {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    margin: 8px 0 0 0;
}

.common-container.users .inner-container .user-detail .user-roles {
    padding: 31px 28px;
    border-bottom: 1px solid #e0e0e0;
}

.common-container.users .inner-container .user-detail .user-roles .main-head {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    /* margin-bottom: 22px; */
}

.common-container.users .inner-container .user-detail .user-roles .add-roles-container {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 42px;
    row-gap: 25px;
}

.common-container.users .inner-container .user-detail .user-roles .add-roles-container .role-tabs {
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 10px 14px;
    justify-content: space-between;
}

.common-container.users .inner-container .user-detail .user-roles .add-roles-container .role-tabs span:nth-of-type(1) {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #333;
}

.common-container.users .inner-container .user-detail .job-management {
    padding: 31px 28px;
}

.common-container.users .inner-container .user-detail .job-management .main-head {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    /* margin-bottom: 22px; */
}

.common-container.users .inner-container .user-detail .job-management .job-tabs {
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
    padding: 14px;
    border-radius: 8px;
    margin-bottom: 15px;
    display: flex;
    align-items: flex-start;
}

.common-container.users .inner-container .user-detail .job-management .job-tabs>img {
    margin-right: 16px;
    width: 29px;
    height: 29px;
    border-radius: 50%;
}

.common-container.users .inner-container .user-detail .job-management .job-tabs .job-in-name div > p {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #101828;
    margin: 4px 0 0 0;
}
.common-container.users .inner-container .user-detail .job-management .job-tabs .job-in-name>p.levels-p {
    font-size: 16px;
    font-weight: normal;
    color: #667085;
}
.common-container.users .inner-container .user-detail .job-management .job-tabs .job-in-name>p.levels-p strong{
    color: #000;
    font-weight: normal;
}
.vertical-middle{
    vertical-align: middle;
}

.common-container.users .inner-container .user-detail .job-management .job-tabs .job-in-name .job-content {
    margin-top: 13px;
    display: flex;
    text-align: left;
    flex-wrap: wrap;
}

.common-container.users .inner-container .user-detail .job-management .job-tabs .job-in-name .job-content span {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #667085;
    width: 100%;
    overflow: hidden;
}

.common-container.users .inner-container .user-detail .job-management .job-tabs .job-in-name .job-content span strong {
    font-weight: 400;
    color: #030229;
}

.add-btn.manage-btn {
    background: transparent;
    border: 1px solid #006AFF;
    color: #006AFF;
    padding: 7px 10px;
    width: 265px;
    text-align: left;
    margin: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.right-arrow-btn {
    transform: rotate(180deg);
}

.delete-can {
    width: 16px;
    height: 16px;
}



.side-bar-parent .inner-container .forms-input label {
    color: #000000;
}

.side-bar-parent .inner-container .forms-checkbox {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
}

.side-bar-parent .inner-container .forms-checkbox label,
.facilities_desc label {
    /* margin-left: 19px; */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
}

.side-bar-parent .inner-container .forms-checkbox span,
.forms-inputfill label,
.facilities_desc span {
    /* margin-left: 17px; */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #666666;
    /* margin-top: 5px; */
}

.forms-inputfill label {
    margin: 0;
    margin-right: auto;
}

.forms-inputfill .company_label{
    color: black;
    font-weight: 600;
}

.forms-inputfill .facility_label{
    margin-right: 0px;
}

.forms-inputfill .main-desc:first-of-type {
    margin-top: 10px;
}

.forms-inputfill .main-desc {
    font-weight: 600;
    font-size: 13px;
    line-height: 22px;
    color: #000000;
    margin-left: 15px;
    position: relative;
}

.forms-inputfill .main-desc::after {
    content: '';
    position: absolute;
    top: 8px;
    left: -15px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #000;
}

.forms-inputfill .sub-desc {
    font-size: 13px;
    line-height: 22px;
    color: #000000;
    margin-left: 15px;
    max-width: 180px;
}

.side-bar-parent .inner-container .forms-checkbox input[type="checkbox"] {
    height: 22px;
    width: 22px;
    left: 5px;
    top: 5px;
    border-radius: 0px;
}

.facilities_desc {
    display: flex;
    flex-direction: column;
}

.forms-inputfill {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
}

.forms-inputfill span {
    margin-left: 20px;

}

.manage-bg {
    background: #FFFFFF;
    border-radius: 14px;
    height: 100%;
}

.manage-bg.active {
    background-color: transparent;
}

.manage-bg .manage-content {
    padding: 20px;
    height: calc(100% - 127px);
    overflow: auto;
}

.manage-title>p {
    margin: 0;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    color: #000000;
    padding: 30px 20px;
    border-bottom: 1px solid #E0E0E0;
}

.manage-bg .manage-content .MuiPaper-root {
    box-shadow: none;
    margin-bottom: 16px;
}

.manage-bg .manage-content .MuiPaper-root:last-of-type {
    margin-bottom: 0;
}

.manage-bg .manage-content .MuiPaper-root::before {
    content: unset;
}

.manage-bg .manage-content .MuiAccordionSummary-root {
    background: #F6F6F8;
    border-radius: 6px;
    padding: 7px 24px;
    max-height: 64px;
    width: 100%;
}

.manage-bg .manage-content .MuiCheckbox-root,
.manage-bg .MuiCheckbox-root {
    padding: 0;
}

.manage-bg .manage-content .right-checks .MuiFormControlLabel-root .MuiTypography-root {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #000000;
    margin-left: 10px;
}

.manage-bg .manage-content .MuiCheckbox-root,
.manage-bg .MuiCheckbox-root {
    color: #3954A5;
}

.manage-bg .manage-content .MuiCheckbox-colorSecondary.Mui-checked,
.manage-bg .MuiCheckbox-colorSecondary.Mui-checked {
    color: #3954A5;
}

.manage-bg .manage-content .MuiAccordionSummary-root.Mui-expanded {
    border-radius: 6px 6px 0 0px;
}

.manage-bg .manage-content .MuiTypography-root {
    font-family: 'Inter';
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #101828;
}

.manage-bg .manage-content .MuiAccordion-region {
    background: #FFFFFF;
    border: 1px solid #F6F6F8;
    border-top: none;
    box-sizing: border-box;
    border-radius: 0px 0px 6px 6px;
    padding: 20px 24px;
}

.manage-bg .manage-content .MuiAccordionDetails-root {
    margin-bottom: 16px;
    padding: 0;
}

.manage-bg .manage-content .MuiAccordionDetails-root:last-of-type {
    margin-bottom: 0;
}

.manage-bg .manage-content .left-content {
    width: 300px;
}

.manage-bg .manage-content .left-content span {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #101828;
}

.manage-bg .manage-content .right-checks {
    display: grid;
    grid-template-columns: auto auto auto auto;
    width: calc(100% - 300px);
}

.manage-bg .manage-content .checks-container {
    margin-bottom: 30px;
}

.manage-bg .manage-content .checks-container:last-of-type {
    margin-bottom: 0px;
}

/* card{
    box-shadow: none;
}
card in{
    background: #F6F6F8;
    border-radius: 6px;
    padding: 7px 24px;
    max-height: 64px;
}
p{
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    font-family: 'Inter';
    color: #101828;
} */

.manage-checkboxes {
    display: grid;
    grid-template-columns: auto auto;
    gap: 20px;
}

/* .checkboxes-right{
    margin-left: 100px;
} */

.job-info {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    background: #fff;
    padding: 15px 5px 15px 5px;
    margin: 0;
}

.common-container.users .inner-container .manage-container.jobs {
    padding: 20px 0;
}

.common-container.users .inner-container .manage-container.jobs .parent-container-d.active {
    height: calc(100% - 110px);
    padding: 0 20px 20px;
    overflow: auto;
}

.common-container.users .inner-container .manage-container.jobs .btns-container {
    padding: 31px 35px;
    border-top: 1px solid #E0E0E0;
    display: flex;
    justify-content: space-between;
}

.common-container.users .inner-container .manage-container.jobs .btns-container .save-btn {
    margin: 0;
    width: auto;
    padding: 12px 50px;
}

.innner-checkboxes {
    margin-left: 0;
}

/* .job-info input[type="checkbox"]{
    height: 22px;
    width: 22px;
    left: 5px;
    top: 5px;
    border-radius: 0px;  
} */

.add-btn.save-btn {
    width: 8%;
    float: right;

}

.forms-inputfill label.note-label{
    background: rgba(255, 127, 0, 0.05);
    border-radius: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    color: #FF7F00;
    padding: 6px 9px;
    width: fit-content;
}

.cancel-btn.save-btn {
    background: #fff;
    color: #101323;
    border: 1px solid #2E90FA;
    border-radius: 8px;
    padding: 20px;
    margin-top: 20px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 5px;
    cursor: pointer;
}
.common-container.users .edit-popup{
    top: 27px;
}


.alert-enter {
    opacity: 0;
    transform: scale(0.9);
}
.alert-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
}
.alert-exit {
    opacity: 1;
}
.alert-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
}
.fade-in-section {
    opacity: 0;
    transform: translateY(10vh);
    visibility: hidden;
    transition: opacity 1200ms ease-out, transform 600ms ease-out,
      visibility 1200ms ease-out;
    will-change: opacity, transform, visibility;
}
.fade-in-section.is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
}
.message {
    align-items: center;
    font-weight: bold;
}

.status-label {
    border-radius: 8px;
    font-size: 12px;
    text-align: center;
    background: #DFE1E6;
    color: #42526E;
}

.status-label.active {
    background: #E3FCEF;
    color: #00875A;
}

.status-label.brown {
    background: #dbc9c5;
    color: #66332a;
}

.status-label.lblue {
    background: #DEEBFF;
    color: #0052CC;
}

.status-label.dblue {
    background: #ced6ed;
    color: #3954A5;
}

.status-label.orange {
    background: #ffe6cc;
    color: #ff8000;
}

.status-label.purple {
    background: #ead7f5;
    color: #a020f0;
}

.status-label.blue {
    background: #2E90FA;
    color: #FFFFFF;
}

.status-label.grey {
    background: #9EA0A5;
    color: #FFFFFF;
}

.common-container.users .inner-container .user-detail .personal-info .personal-data.job-template {
    row-gap: 25px;
}

.wd-45 {
    width: 45%;
}

.common-container.users .inner-container .user-detail .top-header-user .notes {
    padding: 20px 28px;
}

.user-notification-manage-content {
    padding: 24px 0;
    height: calc(100% - 21px);
    overflow: auto;
}