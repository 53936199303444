.white-container {
    background: #fff;
    border-radius: 4px;
    padding: 20px;
    position: relative;
    width: 600px;
    height: fit-content;
    margin: auto;
    top: 90px;
    display: block;
    border: 1px solid #D0D5DD;
    text-align: center;
    font-size: 14px;
    max-height: 660px;
}

.txt_box{
    padding-top: 0px;
    margin-bottom: 10px;
}

.input-container {
    margin: auto;
    width: 50%;
}

.white-container label {
    word-break: break-word;
}

.doc-tab-container {
    height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 30px;
}

.doc-tab {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
