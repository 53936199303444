.settings_card {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    height: calc(100% - 100px);
    margin-bottom: 15px;
    padding-right: 10px;
    overflow: auto;
    align-content: baseline;
}

.settings_grid {
    padding: 20px 20px;
    background: #FFFFFF;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    grid-template-columns: auto auto;
    max-height: 282px;
    width: calc(100% / 2 - 20px);
    transition: 0.5s all;
}

@media only screen and (max-width: 950px) {
    .settings_grid {
        width: 100%;
        min-width: 300px;
    }
}

.content {
    display: flex;
    justify-content: space-between;
    color: #667085;
    font-size: 16px;
}

.textInput {
    border: 1px solid #D0D5DD;
    border-radius: 4.5667px;
    height: 30px;
    padding-left: 12px;
    margin-left: 12px;
}

.heading {
    font-size: 18px;
    margin-bottom: 10px;
}

.save_settings {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    width: 95%;
}

.cancel_btn {
    background: #fff;
    color: #101323;
    border: 1px solid #2E90FA;
    padding: 11px;
    border-radius: 6.5639px;
    width: 11%;
}

.save_btn {
    background: #006AFF;
    color: #fff;
    border: 1px solid #2E90FA;
    padding: 11px;
    border-radius: 6.5639px;
    width: 9%;
    position: absolute;
    right: 75px;
    bottom: 40px;
}

.text {
    width: 140%;
}

.save_settings .save-btn {
    width: auto !important;
    padding: 12px 50px;
}