.parent-outer-main{
    height: calc(100% - 66px);
}
.sidebar-company{
    width: 244px;
    height: 100%;
    background: #F6F6F8;
    overflow: hidden;
}
.sidebar-company .sidebar-header{
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    padding: 18px 16px;
    text-align: left;
    height: 144px;
}
.sidebar-company .sidebar-header .company-logo{
    margin: 0;
    width: 60px;
    height: 60px;
    border: 1px solid #f6f6f8;
    border-radius: 7px;
}
.sidebar-company .sidebar-header .company-name{
    text-align: left;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #1D1C1D;
    margin: 6px 0 0;
}
.sidebar-company .sidebar-header .company-edit{
    position: absolute;
    top: 18px;
    right: 16px;
    margin: 0;
    cursor: pointer;
}
.sidebar-company .sidebar-content{
    padding: 24px 0;
    height: calc(100% - 230px);
    overflow: auto;
}
.sidebar-company .sidebar-content .dashboard{
    padding: 0px 20px;
    margin: 20px 0;
    transition: 0.5s all;
    position: relative;
    height: 30px;
}
.sidebar-company .sidebar-content .dashboard:first-of-type{
    margin-top: 0;
}
.sidebar-company .sidebar-content .dashboard:last-of-type{
    margin-bottom: 0;
}
.sidebar-company .sidebar-content .dashboard.active::after{
    content: '';
    position: absolute;
    left: 0;
    top: 3px;
    height: calc(100% - 10px);
    width: 5px;
    background: #3954A5;
    height: 35px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}
.sidebar-company .sidebar-content .dashboard a{
    padding: 6px 14px;
    border-radius: 15px;
    height: 30px;
    transition: 0.4s ease-in-out;
    width: calc(100% - 28px);
}
.sidebar-company .sidebar-content .dashboard.active a,.sidebar-company .sidebar-content .dashboard:hover a{
    background: #3954A5;
    height: 30px;
}
.sidebar-company .sidebar-content .dashboard.active a span,.sidebar-company .sidebar-content .dashboard:hover a span{
    color: #fff;
}
.navbar.company-active + .parent-outer-main{
    height: calc(100% - 0px);
}