.glider {
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
}

.glide {
    min-width: 15%;
    height: auto;
    transition: 0.5s;
    width: 220px;
    height: 150px;
}

.poster {
    width: 200px;
    height: 100px;
}

.poster.selected {
    border: 2px solid red;
}

.bodyDiv {
    background-color: #f5f5f5;
}

.mainDetails {
    padding-top: 20px;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
}

.poster-video {
    width: 700px;
    display: block;
}

.question-center {
    text-align: left;
}

.grey-border {
    border: 1px solid #9494AE;
}

.green-border {
    border: 4px solid greenyellow;
}

.video-section {
    margin-right: 60px;
}

.profile-data .v-rating {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    font-size: 12px;
    min-width: 200px;
    width: 100%;
}

.rating-section {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    gap: 10px;
}
