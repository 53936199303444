.exports {
    background: #FFFFFF;
    border: 1px solid #AAB4BC;
    border-radius: 8px;
    padding: 5px 10px;
    cursor: pointer;
}

.exports>img {
    margin-right: 5px;
    vertical-align: middle;
}

.exports>span {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #000000;
}

.company_content.help-container .content_header.remove-border {
    border-color: transparent;
    justify-content: space-between;
}

.help-container .company_table.search-page {
    height: calc(100% - 50px);
}

.top-filters {
    background: #F6F6F8;
    border-radius: 14px;
    padding: 10px 20px;
    margin: 0 12px;
    display: flex;
    overflow: auto;
    align-items: flex-end;
}

.top-inputs {
    margin-right: 14px;
}

.top-inputs>p {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #585757;
    margin: 0 0 6px 0;
}

.top-inputs input[type="text"],
.top-inputs select {
    background: #FFFFFF;
    border: 1px solid #D0D5DD;
    box-shadow: 0px 1px 2px rgb(16 24 40 / 5%);
    border-radius: 8px;
    padding: 8px 14px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #101828;
    max-width: 146px;
}

.top-inputs select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    background-image: url("data:image/svg+xml;utf8,<svg fill='gray' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: calc(100% - 10px);
    background-position-y: 8px;
    width: 146px;
    outline: none;
    cursor: pointer;
    border-color: #006AFF;
}

.top-inputs input[type="text"].search-in-filter {
    max-width: 100%;
    width: 320px;
}

.top-filters button {
    background: #006AFF;
    border-radius: 8px;
    border: none;
    padding: 10px 14px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #fff;
    cursor: pointer;
}

.resume-found {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #475467;
    margin: 10px 20px 10px 20px;
}

.resume-cards {
    margin: 0 20px;
    height: calc(100% - 184px);
    overflow: auto;
}

.resume-cards .perticular-cards {
    display: flex;
    background: #FFFFFF;
    border: 1px solid #EAECF0;
    border-radius: 14px;
    align-items: center;
    padding: 14px;
    margin-bottom: 10px;
}

.resume-cards .perticular-cards .profile-img {
    width: 65px;
    height: 66px;
    border-radius: 50%;
    margin-right: 20px;
}

.resume-cards .perticular-cards .profile-img.global {
    width: 79px;
    height: 80px;
}

.resume-person {
    margin: 0 0 4px 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #101828;
}

.work-design,
.zip-code,
.rating-stars,
.email-resume,
.phone-num {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #101828;
    margin: 4px 0 0;
}

.email-resume,
.phone-num {
    color: #475467;
}

.rating-stars {
    color: #667085;
}

.dot {
    width: 7px;
    height: 7px;
    border-radius: 100%;
    margin: 5px 20px 0 20px;
    background-color: #475467;
}

.rating-stars img:first-of-type {
    margin-left: 9px;
}

.rating-stars img {
    width: 17px;
    height: 17px;
    margin: 0 5px;
    vertical-align: middle;
    margin-bottom: 4px;
}

.tags {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    padding: 2px 10px;
    color: #00875A;
    max-width: fit-content;
    height: 19px;
    margin: 0;
    background: #E3FCEF;
    border-radius: 3px;
}

.tags.global {
    margin-left: 15px;
}

.dates-resume {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #828282;
    margin: 0 0 0 auto;
}

.resume-person.global {
    margin: 0;
}

.right-side-content {
    width: calc(100% - 109px);
}

.res-details {
    display: grid;
    grid-auto-flow: column;
    align-items: flex-end;
}
.res-details-job {
    grid-template-columns: repeat(4, minmax(0, 2fr)); 
}
