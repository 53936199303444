.App > div{
    position: relative;
}
.sidebar{
    width: 244px;
    height: calc(100% - 66px);
    filter: drop-shadow(4px 0px 4px rgba(0, 0, 0, 0.12));
    background: #FFFFFF;
    overflow: hidden;
}

.sidebar-header{
    border-bottom: 1px solid #E0E0E0;
    padding: 35px 0;
    text-align: center;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 22px;
}
.sidebar-header > img{
    margin: 0px 45px;   
}
.sidebar-heading{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #3E3F42;    
    margin: 0;
}

.sidebar-divider{
    position: absolute;
    width: 244px;
    height: 0px;
    left: 0px;
    top: 96px;
    border: 1px solid #E0E0E0;
}

.sidebar-content{
    padding: 10px 0;
    height: calc(100% - 113px);
    overflow: auto;
    overflow-x: hidden;
}

.dashboard{
    margin: 10px 0;
    transition: 0.4s all;
}
.dashboard > a{
    text-decoration: none;
    display: flex;
    align-items: center;
    width: 244px;
}
.dashboard > a:hover .dashboard_text{
    color: #3954A5;
}
a{
    text-decoration: none;
}
.dashboard_text{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 48px;
    color: #3E3F42;
    text-decoration: unset;
}

.sidebar-icon{
    margin: 0 45px;
}
.dashboard > a.active .sidebar-icon{
    padding: 12px 21px;
    background: #3954A5;
    border-radius: 15px;
    margin: 0 22px;
}
.dashboard > a.active{
    border-left: 4px solid #3954A5;
}
.dashboard > a.active .dashboard_text{
    color: #3954A5; 
}
.dashboard > a.active svg path{
    stroke: #fff;
}



.selected_text{
    color: #3954A5;
    /* margin-top: 40.75%; */
}

.heading-line1{
    position: absolute;
    width: 10.91px;
    height: 2.91px;
    left: 40px;
    top: 40px;
    background: #3954A5;
}

.heading-line2{
    position: absolute;
    width: 21.09px;
    height: 2.91px;
    left: 40px;
    top: 46.55px;
    background: #3954A5;
}

.heading-line3{
    position: absolute;
    width: 18.18px;
    height: 2.91px;
    left: 40px;
    top: 53.09px;
    background: #3954A5;
}

.icon{
    cursor: pointer;
}

.icon_button{
    background-color: Transparent;
    border: none; 
}
.sidebar.company-active{
    height: calc(100% - 0px);
}
.sidebar.company-active .sidebar-content{
    height: calc(100% - 117px)
}
.sidebar.company-active .sidebar-header{
    padding: 23px 0;
}
.sidebar.company-active .sidebar-header > img{
    margin: 0 18px;
    width: 50px;
    height: 50px;
}
.sidebar.company-active .sidebar-heading{
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    text-transform: uppercase;
    color: #000000;
}

.sidebar, .company_content{
    transition: 0.6s all;
}
.sidebar.collapse, .navbar.company-active.collapse + .sidebar{
    width: 114px;
}
.sidebar.collapse .dashboard_text, .sidebar.collapse .sidebar-heading,
.navbar.company-active.collapse + .sidebar .dashboard_text, .navbar.company-active.collapse + .sidebar .sidebar-heading{
    display: none;
}
.sidebar.collapse .dashboard > a, .navbar.company-active.collapse + .sidebar .dashboard > a{
    width: 114px;
}
.sidebar.collapse .sidebar-icon, .sidebar.company-active.collapse .sidebar-header > img,
.navbar.company-active.collapse + .sidebar .sidebar-icon, .navbar.company-active.collapse + .sidebar .sidebar-header > img{
    margin: 0 auto;
}
.sidebar.collapse + .company_content, .navbar.company-active.collapse + .sidebar.company-active + .company_content{
    width: calc(100% - 210px);
    left: 144px;
}
.sidebar.collapse + .company_content.help-container, .navbar.company-active.collapse + .sidebar.company-active + .company_content.help-container{
    width: calc(100% - 170px);
}
