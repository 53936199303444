select{
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    background-color: #fff;
    background-image: url("data:image/svg+xml;utf8,<svg fill='gray' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: calc(100% - 10px);
    background-position-y: 8px;
    outline: none;
    cursor: pointer;
    border: 1px solid #D0D5DD;
    border-radius: 8px;
    padding: 8px 14px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #101828;
    max-width: 256px;
    width: 100%;
}
.mr-auto{
    margin-right: auto !important;
}
.applicants-icon{
    margin: 0 16px;
}
.applicants-icon:last-of-type{
    /* margin-right: 42px; */
}
.applicant-cards{
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
}
.app-card{
    padding: 8px 16px;
    border-radius: 4px;
    background: #fff;
    width: 300px;
}

@media only screen and (min-width: 1600px) {
    .applicant-cards {
        gap: 20px;
    }

    .app-card {
        width: calc(100% / 4 - 50px);
    }
}

@media only screen and (min-width: 1150px) and (max-width: 1600px) {
    .applicant-cards {
        gap: 20px;
    }

    .app-card {
        width: calc(100% / 3 - 50px);
    }
}

@media only screen and (min-width: 800px) and (max-width: 1150px) {
    .applicant-cards {
        gap: 20px;
    }

    .app-card {
        width: calc(100% / 2 - 50px);
    }
}

@media only screen and (min-width: 620px) and (max-width: 800px) {
    .applicant-cards {
        gap: 20px;
    }

    .app-card {
        width: calc(100% - 50px);
    }
}

.app-card .dates{
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #101828;
    margin: 0 0 0 0;
}
.app-card .app-card-profile{
    width: 44px;
    height: 44px;
    border-radius: 21px;
}
.app-card .app-profile-name{
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #101828;
    margin: 0 0 0 10px;
}
.app-card .app-designation{
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #344054;
    margin: 6px 0;
}
.app-card .app-status{
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #98A2B3;
    margin: 0;
}
.company_content.help-container.applicants .content_header{
    padding: 0;
    background: #F6F6F8;
    padding-bottom: 24px;
    border: none;
}
.help-container.applicants{
    background: #F6F6F8;
    height: calc(100% - 93px);
}
.help-container.applicants .company_table {
    margin: 0;
    /* height: calc(100% - 66px); */
}
.help-container.applicants .notification-container{
    padding: 0;
    height: 100%;
}
.align-items-start{
    align-items: start;
}
.more-app{
    margin-top: 7px;
}
.hired{
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    background: #00875A;
    border-radius: 3px;
    padding: 2px 4px;
    margin: 0 14px 0 0;
    color: #fff;
    cursor: default;
}
.hotList-p{
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #344054;
    margin: 6px 0;
}
.hotList-p:first-of-type{
    margin-top: 12px;
}
.hotList-p:last-of-type{
    margin-bottom: 12px;
}
.hotList-p strong{
    font-weight: normal;
}
.hotList-p strong.active-status{
    color: #00875A;
}
.hotList-p strong.eligible{
    color: #E6492D;
}
.hotList-p strong.eligible-two{
    color: #0052CC;
}
.inputs-search{
    background: #FFFFFF;
    border: 1px solid #C7D2E6;
    border-radius: 8px;
    padding: 9px 23px 7px 48px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #1D1C1D;
}
.inputs-search::placeholder{
    color: #9494AE;
}
.search-icon-hotlist{
    position: relative;
    height: 0;
}
.search-icon-hotlist::after{
    content: "";
    position: absolute;
    top: -8px;
    left: 18px;
    width: 18px;
    height: 18px;
    display: block;
    background-size: 100%;
    background-repeat: no-repeat;
    background-image: url("../../../images/search.svg");
}

.common-container.users.company .inner-container {
    background-color: #F6F6F8;
    position: absolute;
    height: 96%;
    width: 100%;
    left: 0;
    top: 30px;
    padding: 0;
}

.common-container.users.company .inner-container.calendar {
    background-color: #F6F6F8;
    position: absolute;
    height: 95%;
    width: 100%;
    left: 0;
    top: 40px;
    padding: 0;
}

.top-url p {
    color: #828282;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin: 0;
}

.top-url p strong {
    color: #1d1c1d;
    font-weight: 400;
}

.company_search.company {
    margin-right: 90px;
}

.applicant .MuiDropzoneArea-root {
    min-height: 160px;
}

.show-details {
    padding: 8px 16px 8px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
}

.applicant-show-details {
    display: flex;
    flex-direction: column;
    padding: 0px 16px 8px 16px;
    gap: 5px;
    font-size: 12px;
}

.show-tab {
    display: flex;
    gap: 20px;
}