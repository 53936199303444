/* -------------------------------------------Companylist css -----------------------------------------------*/
.App > div {
  position: relative;
  background-color: #f6f6f8;
}

.company_content {
  position: absolute;
  width: calc(100% - 325px);
  height: calc(100% - 115px);
  left: 274px;
  top: 93px;
  border-radius: 14px;
  padding: 0 0 0 10px;
}

.content_header {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #dbdbdb;
  padding-bottom: 24px;
}
.company_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #1d1c1d;
}
.company_text.mr-20 {
  margin-right: 20px;
}
.search-btn {
  background: #3a54a6;
  color: #fff;
  padding: 8px;
  border-radius: 8px;
  font-size: 14px;
  font-family: "Inter";
  position: absolute;
  top: 0px;
  height: 38px;
  /* right: 4px; */
  border: none;
  cursor: pointer;
  margin-left: 33px;
}

.company_search {
  max-width: 350px;
  height: 40px;
  /* margin-left: 47px; */
  background: #ffffff;
  border: 1px solid #c7d2e6;
  box-sizing: border-box;
  border-radius: 8px;
  position: relative;
  margin-right: auto;
}

.search_img {
  margin-top: 8px;
  vertical-align: bottom;
  margin-left: 7px;
  margin-right: 3px;
  /* cursor: pointer; */
}

.search_input {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* color: #9494AE; */
  color: #000000;
  border: none;
  width: calc(100% - 60px);
}

.company_sort {
  display: flex;
  align-items: center;
}

.sort_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  text-transform: uppercase;
  color: #1d1c1d;
}

.text_light {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  text-transform: uppercase;
  color: #87849e;
}

.company_view {
  display: flex;
}

.company_view > button {
  display: flex;
  align-items: center;
}

.company_refresh > button {
  width: 40px;
  height: 40px;
  background: #f2f2f2;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.company_refresh > img {
  height: 24px;
  width: 24px;
}

.company_add {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin-left: 0px;
}

.company_add .add_input {
  margin-left: 5px;
}

.add_input {
  width: 74px;
  height: 40px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  background-color: #2e90fa;
  border: none;
  border-radius: 8px;
  color: #ffffff;
  cursor: pointer;
  margin-left: 36px;
}

.icon {
  cursor: pointer;
}

.edit_icon {
  padding-right: 20px;
}

.sort_icon {
  padding-left: 20px;
}

.company_list_logo {
  width: 36px;
  height: 36px;
  margin-right: 20px;
  border: 1px solid #e0e0e0;
  vertical-align: middle;
}

.company_table {
  width: 100%;
  margin-top: 14px;
  height: calc(100% - 120px);
  overflow: auto;
}
table {
  width: 100%;
  border-spacing: 0;
}

th {
  border-bottom: 1px solid #dbdbdb;
  padding-bottom: 12px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #9494ae;
  text-transform: uppercase;
}

.company_content .content_header .company_search {
  margin-left: 0px;
}

td {
  text-align: center;
  padding: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  color: #1d1c1d;
  opacity: 1;
  border-bottom: 1px solid #efefef;
}

.th_name,
.td_name {
  text-align: left;
}

.td_name {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  padding-left: 0px;
  color: black;
}

.td_name:hover {
  cursor: pointer;
}

.company_thead {
  position: absolute;
  width: 1132px;
  height: 48px;
  left: 40px;
  top: 83px;
}

.thead_text {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 143px;
  height: 20px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  color: #9494ae;
}

.col1 {
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px;
}

.col2 {
  flex: none;
  order: 1;
  flex-grow: 0;
  margin-left: 308px;
}

.col3 {
  flex: none;
  order: 2;
  flex-grow: 0;
  margin-left: 70px;
}

.col4 {
  flex: none;
  order: 3;
  flex-grow: 0;
  margin-left: 56px;
}

.col5 {
  flex: none;
  order: 4;
  flex-grow: 0;
  margin-left: 40px;
}

.col6 {
  flex: none;
  order: 5;
  flex-grow: 0;
  margin-left: 96px;
}

.company_tbody {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  position: absolute;
  width: 1134px;
  height: 684px;
  left: 40px;
  top: 231px;
}

.th_divider {
  width: 1132px;
  height: 0px;
  left: 274px;
  top: 183px;
  /* opacity: 0.05; */
  border: 1px solid #000000;
}

/* -------------------------------------------Companycard css -----------------------------------------------*/

.company_card {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  height: calc(100% - 140px);
  /* margin-bottom: 15px; */
  padding-right: 10px;
  overflow: auto;
  align-content: baseline;
}

.company_grid {
  padding: 10px 10px;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 11.5667px;
  display: grid;
  grid-template-columns: auto auto;
  max-height: 282px;
  width: calc(100% / 3 - 20px);
  max-width: 210px;
  transition: 0.5s all;
  box-shadow: 0px 20px 45px #f0edf7;
  border-radius: 10px;
}
.company_grid:hover {
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
  cursor: pointer;
}

.company_grid > div {
  margin: 7px;
}

.grid_1 {
  /* grid-column: 1 / span 2; */
  /* background: antiquewhite; */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  border-radius: 10px;
}

.company_card .company_grid:nth-of-type(9) .grid_1 svg {
  width: 18px;
  height: 20px;
}

.grid_2 {
  grid-column: 1 / span 2;
  font-size: 16px;
  line-height: 1;
}
.grid_4 {
  grid-column: 1 / span 2;
  line-height: 1 !important;
}

.company_card_logo {
  /* float: left; */
  border-radius: 8.2619px;
}

.icon_button {
  background-color: Transparent;
  border: none;
}

.option_menu {
  float: right;
  margin-top: 10px;
  position: relative;
}

.grid_values {
  font-family: "Inter";
  font-style: normal;
  font-weight: 550;
  font-size: 16px;
  line-height: 24px;
  /* color: #101828; */
  color: black;
}

.grid_keys {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #667085;
}

@media only screen and (min-width: 1600px) {
  .company_grid {
    width: calc(100% / 4 - 20px);
  }
}
@media only screen and (max-width: 1300px) {
  /* .company_view, .company_refresh > button {
        margin-left: 10px;
    }
    .add_input{
        margin-left: 15px;
    }
    .company_search {
        max-width: 250px;
        margin-left: 17px;
    } */
}
@media only screen and (max-width: 1276px) {
  .company_grid {
    width: calc(100% / 2 - 20px);
  }
}

.cke_reset_all * {
  opacity: 1;
}

.eye:hover {
  fill: #006aff;
}

.print:hover {
  fill: #006aff;
}

.email:hover {
  fill: #006aff;
}

.MuiDropzoneArea-root {
  width: 100%;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  background-color: #dbdbdb !important;
  padding: 10px;
  border-radius: 7px !important;
  min-height: 75px !important;
  border: none !important;
}

.MuiDropzoneArea-textContainer {
  text-align: center;
  height: 60px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row-reverse;
}

.MuiTypography-h5 {
  font-size: 1.1rem !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  letter-spacing: 0em;
  margin-left: 10px;
}

.MuiDropzoneArea-text {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.MuiDropzoneArea-icon {
  color: #525252 !important;
  width: 50px !important;
  height: 50px !important;
}

.MuiGrid-spacing-xs-8 {
  width: 200% !important;
}

.svg-bg {
  background: #3954a526;
  padding: 20px;
  border-radius: 100%;
}
.react-datepicker__input-container {
  width: 45px !important;
}
.year-picker {
  margin-left: 25px;
  margin-right: 10px;
}
.year-picker .react-datepicker__input-container {
  width: 55px !important;
}
.react-datepicker__input-container .custom-date-input {
  border: none;
  width: 100%;
  position: relative;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  cursor: pointer;
  color: #1f4173;
}
.react-datepicker__input-container .custom-date-input::before {
  content: "\f107";
  font-family: "Font Awesome 6 Free";
  position: absolute;
  top: 50%;
  right: 0;
  z-index: 1;
  transform: translate(0, -50%);
}
.react-datepicker__month-container
  .react-datepicker__header.react-datepicker-year-header,
.react-datepicker__navigation[aria-label="hideYear"] {
  display: none;
}
.date-to {
  font-weight: 500;
  font-size: 12px;
  color: #9ea0a5;
  text-transform: uppercase;
  margin-inline: 15px;
}
