.user-details-tab{
    width: 100%;
    overflow: unset;
    height: max-content;
    background: #FFFFFF;
    border-radius: 14px;
}

.applicant-tab-main-container{
    width: calc(100% - 366px);
    background: #FFFFFF;
    border-radius: 14px;
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: fit-content;
}

.tabs{
    height: 20px;
    width: 20px;
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 6px;
    border-radius: 8px;
    margin-block: 4px;
}

.tab-container{
    display: flex;
    flex: 1; 
    flex-direction: column;
    /* padding: 8px; */
    min-height: max-content;
}

.heading-label{
    font-weight: 600;
    font-size: 14px;
}

.resume-content-container{
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.resume-content-container.file-upload{
    flex-direction: column;
}

.resume-details{
    display: flex;
    flex:1;
    align-items: center;
    /* height: 46px; */
}

.cv-icon-container{
    display: flex;
    height: 44px;
    width: 44px;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
}

.cv-icon-container.pdf {
    background-color: #FFEBEB;
}

.cv-icon-container.doc {
    background-color: #DEEBFF;
}

.resume-content-container .MuiDropzoneArea-root {
    min-height: 150px;
}

.job-document.resume {
    justify-content: flex-end;
}

.details-container{
    display: flex;
    flex:2;
    justify-content: space-around;
    align-items: flex-start;
    flex-direction: column;
    margin-left: 20px;
    height: 50px;
}

.resume-name{
    font-size: 12px;
    font-weight: 600;
    word-break: break-all;
}

.resume-status{
    font-size: 12px;
    font-weight: 500;
    color: #828282;
}

.options-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 110px;
}

.icon-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    cursor: pointer;
}

.icon-info{
    display: flex;
    flex:1;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    height: 56px;
}

.cv-icon-info{
    display: flex;
    flex:1;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    height: 30px;
}

.icon-border-right{
    border-right: 1px solid #F2F2F2;
}

.icon-label{
    font-size: 12px;
    color: #9EA0A5;
    padding-top: 4px;
}

.notes-header-container{
    display: flex;
    flex:1;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.notes-cards-container{
    display: flex;
    flex: 1;
    overflow: auto;
    padding-right: 8px;
    /* margin-top:8px; */
    max-height: 320px;
    flex-direction: column;
}

.notes-card{
    display: flex;
    flex-direction: column;
    background-color: #F6F6F8;
    padding: 10px 14px;
    border-radius: 12px;
    margin-top: 12px;
    word-break: break-word;
}

.card-header{
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.name-label{
    display: flex;
    flex:1;
    color: #101828;
    font-size: 14px;
    font-weight: 600;
}

.header-detail{
    display: flex;
    /* flex:1; */
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    /* margin-right: 16px; */
}

.header-labels{
    color: #98A2B3;
    font-size: 12px;
}

.dot-div{
    height: 8px;
    width: 8px;
    margin-inline: 8px;
    border-radius: 50%;
    background-color: #98A2B3;
}

.notes-desc{
    color: #475467;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    align-items: center;
}

.doucments-card{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #FAFAFA;
    padding: 10px;
    border-radius: 12px;
    margin-top: 16px;
}

.doc-detail{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.doc-title{
    display: flex;
    justify-content: space-between;
}

.file-name-label{
    font-weight: 500;
    font-size: 16px;
    color: #333333;
    margin-left: 8px;
    word-break: break-all;
    font-size: 14px;
}

.doc-icon{
    margin-right: 16px;
}

.checkbox-container.applicants{
    display: flex;
    flex:1;
    flex-direction: row;
    background-color: #FAFAFA;
    padding: 4px;
    justify-content: space-between;
    align-items: center;
    border-radius: 12px;
    /* margin-top: 16px; */
}

.hot-applicant-note-container{
    display: flex;
    flex:1;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 8px;
}

.text-area-container{
    display: flex;
    flex: 1;
    overflow: hidden;
    border: 1px solid #9494AE;
    padding: 8px;
    border-radius: 6px;
    margin-top: 12px;
}

.applicant-btn-container{
    display: flex;
    margin-top: 16px;
}

.hot-list-icon{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #9EA0A5;
    border-radius: 50%;
    height: 26px;
    width: 26px;
    margin-right: 6px;
}


.chat-container{
    display: flex;
    flex: 1;
    flex-direction: column;
    max-height: 320px;
    overflow: auto;
    /* padding-right: 8px; */
    margin: 0 16px;
    background-color: #F6F6F8;
}

.message-chat-container{
    display: flex;
    flex:1;
    margin-block: 6px;
}

.message-chat{
    display: flex;
    flex-direction: column;
    max-width: 75%;
    padding-inline: 14px;
    padding-block: 6px;
    border-radius: 8px;
    height: fit-content;
    word-break: break-word;
}

.chat-design{
    position: absolute;
    height: 20px;
    width: 20px;
}

.message-text{
    margin-top: 4px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #333333;
}

.sender-label{
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    color: #101828;
}

.chat-footer{
    display: flex;
    flex:1;
    justify-content: flex-end;
    align-items: center;
    max-height: 24px;
}

.chat-footer-detail{
    display: flex;
    flex:1;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.chat-footer-labels{
    color: #666668;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
}

.chat-main-footer{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.attach-send{
    display: flex;
    align-items: center;
}

.attachment-container{
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
}

.attachemt-item{
    padding: 12px;
}

.attachment{
    display: flex;
    flex-direction: row;
    padding: 14px;
    align-items: center;
    justify-content: space-between;
    background-color: #F6F6F8;
    border-radius: 8px;
}

.attachment-close-icon-container{
    display: flex;
    align-items: center;
    cursor: pointer;
}

.chat-quick-note-container{
    display: flex;
    flex-direction: column;
    margin-block: 12px;
}

.chat-quick-note-container span {
    font-size: 14px;
}

.review-edit {
    display: flex;
    /* width: 144px; */
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
}

.multiple .MuiFormControl-root.css-1nrlq1o-MuiFormControl-root .MuiFormGroup-root.css-dmmspl-MuiFormGroup-root {
    display: flex;
    flex-direction: row;
}

.forms-input span.mt-10 {
    text-align: right;
    font-size: 0.87rem;
    font-weight: 600;
    color: #006AFF
}

.time-slots {
    display:flex;
    flex-direction: column;
    font-size: 14px;
}

.time-slots .slot-container {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    height: calc(100% - 30px);
}

.time-slots .slot{
    font-size: 10px;
    padding: 4px;
    background: #F2F5FF;
    border-radius: 5px;
    /* border-radius: 5px; */
    border: 1px solid #3954A5;
    color: #3954A5;
}

.time-slots .slot:hover {
    background:#3954A5;
    color:#F2F5FF;
}

.time-slots .slot.selected {
    background:#3954A5;
    color:#F2F5FF;
}

.time-slots .slot.disabled {
    background:#d1d5db;
    color: #52525b;
    border: 1px solid #52525b;
}

.ml-20 {
    margin-left: 20px;
}

.applicant-chat {
    width: 60%;
    max-height: 774px !important;
    margin: auto;
    top: 50px;
    padding: 20px;
}

.chat-container.applicant {
    min-height: 520px;
}

.chat-container .no-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 190px 0px 190px 0px;
}

.resume-content-container .MuiDropzoneArea-root .MuiDropzoneArea-textContainer {
    text-align: center;
    height: 58px;
    display: flex;
    justify-content: center;
    flex-direction: row-reverse;
    align-items: center;
    width: 100%;
}

.MuiDropzoneArea-textContainer .MuiTypography-h5 {
    margin-left: 15px;
}

.participants {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    justify-content: flex-end;
    color: #006AFF;
    font-weight: 600;
    align-items: center;
}

.sign-label {
    color: #006AFF;
}

.applicant-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 10px 5px 10px;
    background: #DEEBFF;
    /* margin-top: -9px; */
}

.common-container .inner-container .user-container .user-list.applicant-h {
    background: #FFFFFF;
    box-shadow: inset 0 15px 0 0 #DEEBFF;
    position: relative;
    /* overflow: auto;
    overflow-x: hidden;    */
}

.user-details-tab.applicants {
    height: calc(100% - 400px);
}

.inner-h.job-sidebar.no-data {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.checkbox-eye {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.chat-icon {
    margin: auto;
    font-size: 20px;
}

.chat-icon-label {
    font-size: 12px;
    padding: 0 26px;
    text-align: center;
}

.no-message-container {
    background: #F6F6F8;
    margin: 0 16px;
}

.user-list.applicant-h .column-content {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100% - 30px);
}

.calendar-card {
    border: 1px solid #E0E0E0;
    border-radius: 8px;
    padding: 10px;
    margin: 10px 0px;
    font-size: 14px;
    display: flex;
    flex-direction: column;
}

.cal-title {
    color: #667085;
    font-weight: 600;
    margin-right: 10px;
}

.calendar-card label {
    color: #98A2B3;
}
