.g-card-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 15px;
}

.g-card {
    background: #FAFAFA;
    padding: 10px;
    border-radius: 12px;
    margin: 10px;
}

.g-card-header {
    font-weight: 600;
    color: #333333;
    display: flex;
    justify-content: space-between;
    width: 85%;
}

.g-card-header span {
    color:#828282;
    font-weight: 100;
}

.g-card-header-2 {
    color: #333333;
    font-size: 12px;
}

.g-card-content {
    font-size: 0.83rem;
    color: #333333;
}

.g-card-content span {
    font-weight: 600;
}

.mt-10 {
    margin-top: 10px;
}

.mt-15 {
    margin-top: 15px;
}

.mt-16 {
    margin-top: 16px;
}

.g-card-dark-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 264px;
}

.g-card-dark-header span {
    font-weight: bold;
    font-size: 14px;
}

.g-card-content-sections {
    display: flex;
    justify-content: space-between;
    font-size: 0.9rem;
}

.g-card-content-sections div {
    font-weight: bold;
}

.g-card-content-sections div span {
    color: #667085;
    font-weight: normal;
}

.j-card-container {
    display: flex;
    gap: 10px 10px;
    flex-wrap: wrap;
    height: calc(100% - 130px);
    /* margin-bottom: 15px; */
    overflow: auto;
    align-content: baseline;
}

.j-card {
    padding: 12px 20px;
    position: relative;
    width: 300px;
    transition: 0.5s all;
    background: #FFFFFF;
    border: 1px solid #E6E6E8;
    border-radius: 5px;
    margin: 10px;
}

@media only screen and (min-width: 1600px) {
    .j-card-container {
        gap: 10px 10px;
    }

    .j-card {
        width: calc(100% / 4 - 70px);
    }
}

@media only screen and (min-width: 1300px) and (max-width: 1600px) {
    .j-card-container {
        gap: 10px 10px;
    }

    .j-card {
        width: calc(100% / 3 - 70px);
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1300px) {
    .j-card-container {
        gap: 10px 10px;
    }

    .j-card {
        width: calc(100% / 2 - 70px);
    }
}

@media only screen and (min-width: 780px) and (max-width: 1086px) {
    .j-card-container {
        gap: 10px 10px;
    }

    .j-card {
        width: calc(100% / 2 - 70px);
        /* min-width:  200px; */
    }
}

@media only screen and (min-width: 650px) and (max-width: 780px) {
    .j-card-container {
        gap: 10px 10px;
    }

    .j-card {
        width: calc(100% - 40px);
    }
}

.j-card-header {
    display: flex;
    font-size: 12px;
    justify-content: space-between;
    align-items: center;
    height: 24px;
}

.j-card-header div {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.status-label {
    font-weight: 600;
    border-radius: 5px;
    padding: 4px;
}

.status-label.red {
    color: #E6492D;
    background-color: #FFEBE6;
}

.status-label.yellow {
    background: #FFF0B3;
    color :#172B4D;
}

.w-50 {
    width: calc(50% / 3 - -116px)
}

.j-card-content {
    display: flex;
    flex-direction: column;
}

.j-card-content div {
    display: flex;
    flex-direction: row;
    /* width: 75%; */
    justify-content: space-between;
    font-size: 0.9rem;
}

.j-card-content div.show-more {
    font-weight: 500;
    color: #667085;
    height: 80px;
}

.j-card-content div.show-more.less {
    height: 74px;
}

.j-card-content span {
    margin-top: 10px;
    font-weight: 500;
    color: #667085;
}

.j-card-content .title {
    font-size: 1.05 rem;
    color: #101828;
    font-weight: 600;
}

.j-card-content label {
    color: #101828;
    font-weight: 400;
}

.fts-09 {
    font-size: 0.9rem;
}

.profile-div-border {
    border-bottom: 1px solid #E0E0E0;
}

.g-card.profile {
    background-color: #ffff;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 8px;
    margin: 6px 0px;
    border-radius: 0px;
    border-bottom: 1px solid #E0E0E0;
}

.profile-img {
    margin-right: 20px;
}

.profile-img img{
    width: 75px; 
    border-radius: 100px;
}

.applicant-img img {
    width: 100px;
    border-radius: 100px;
    margin-bottom: 10px;
}

.profile-content {
    display: flex;
    flex-direction: column;
    width: 93%;
}

.date-arrow {
    font-size: 12px;
}

.date-arrow div {
    color: #828282;
    display: flex;
    align-items: center;
}

.mt-5 {
    margin-top: 5px;
}

.blue-t-normal {
    color: #006AFF;
}

.g-card-header-2 label {
    color: #475467;
    font-size: 12px;
}

.j-card-content label.blue-text {
    color: #006AFF;
}

.p-content {
    display: flex;
    justify-content: space-between;
    word-break: break-all;
}

.p-case-content {
    display: flex;
    justify-content: space-between;
    word-break: break-all;
    /* border-top: 1px solid #E0E0E0; */
    padding-top: 15px;
}

.mr-10 {
    margin-right: 10px;
}

.mr-5 {
    margin-right: 5px;
}

.top-header-user div {
    display: flex;
}

.top-header-user div button {
    margin-left: 0px;
}

.g-card-container .chkbox {
    color: #828282;
    font-weight: 500;
}

.g-card.job-data {
    background-color: #F6F6F8;
    margin: 10px 0px;
    border-radius: 8px;
}

.g-card.job-data .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.g-card.job-data .header .d-flex span {
    font-weight: 500;
    font-size: 14px;
}

.g-card.job-data .j-content {
    /* margin-top: 7px; */
    font-size: 12px;
    color: #333333;
}

.w-card-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.w-card {
    background-color: #FFFFFF;
    padding: 20px;
    border-radius: 6px;
    margin: 10px;
    display: flex;
    justify-content: space-between;
}

.p-content.border {
    border: 1px solid #42526E;
    border-radius: 5px;
}

.p-content.border span {
    width: 90%;
    border-right: 1px solid #42526E;
    padding: 7px 10px;
}

.p-content.border div {
    text-align: center;
    padding: 5px 10px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: #DFE1E6;
}